<template>
    <div class="course-faq-btm">
        <div class="course-landing-title">Ответы на вопросы</div>

        <div class="course-faq-btm__faq">
            <div class="course-faq-btm__faq-item"
                v-for="(item, index) in questions"
                 :class="{'isActive': item.isActive}"
                 :key="index"
            >
                <div class="course-faq-btm__faq-title"
                     v-html="item.question"
                     @click="toggleActiveState(index)"
                ></div>
                <div class="course-faq-btm__faq-content" v-html="item.answer"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseTarget',
  data() {
    return {
      questions: [
        {
          question: 'Какие есть способы оплаты, рассрочки и предоплаты?',
          answer: '<p>Способов оплаты очень много, поэтому каждый желающий из любой страны найдет решение для себя. Подробнее про все способы <a href="javascript:;" class="readAboutPayment">читайте выше</a> </p>',
          isActive: false,
        },
        {
          question: 'Не получается оплатить?',
          answer: '<p>Так бывает из-за большой нагрузки на платежные системы. Попробуйте повторить попытку через несколько минут.'
          + ' Если проблема не решится, <a href="https://t.me/elenasiaglo_marathonbot  ">обратитесь в службу заботы</a> , вам ответят в течение 10 минут. </p>',
          isActive: false,
        },
        {
          question: 'Что делать после оплаты?',
          answer: '<p>2 октября в 11:00 мск откроется доступ к первому уроку на платформе Геткурс. Для тех,'
          + ' кто на тарифе с кураторами “Ключик с бриллиантом”, на почту придет ссылка на закрытый чат в телеграм.</p>',
          isActive: false,
        },
        {
          question: 'Как подготовиться к курсу?',
          answer: '<p>Чтобы смотреть уроки, установите на телефон приложение Геткурс.'
          + ' Также уроки можно смотреть на компьютере. Сразу после оплаты на вашу '
          + 'почту придет письмо с инструкцией и доступами к вашему Личному кабинету.</p>',
          isActive: false,
        },
        {
          question: 'Из чего состоит урок на GetCourse?',
          answer: '<p>На платформе Геткурс 2 раза в неделю'
          + ' будут открываться новые уроки. Уроки в '
          + 'формате видео по 2-3 часа каждый. Для каждого видео сделаны тайм-коды'
          + ' для удобного изучения материала. Так же для каждого урока прилагается'
          + ' большой конспект в текстовом формате, чек-листы, метафорические карты,'
          + ' скрипты с алгоритмами для выполнения заданий в парах, и другие полезные материалы.</p>',
          isActive: false,
        },
        {
          question: 'Смогу ли я вернуть деньги, если курс не понравится?',
          answer: 'Если в течение первой недели обучения вы решите, '
          + 'что курс вам не подходит, тогда вы можете запросить возврат всей суммы.',
          isActive: false,
        },
      ],
    };
  },
  computed: {
  },
  mounted() {
    this.scrollToPaymentRules();
  },
  methods: {
    scrollToPaymentRules() {
      document.querySelector('.readAboutPayment').addEventListener('click', () => {
        const hiddenElement = document.querySelector('#paymentFormAnchor');
        hiddenElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
      });
    },
    toggleActiveState(index) {
      this.questions.forEach((item, indexItem) => {
        const currentItem = this.questions[indexItem];

        if (index === indexItem) {
          currentItem.isActive = !currentItem.isActive;
        } else {
          currentItem.isActive = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-faq-btm {
    padding: 40px 23px 40px 23px;
    background: $landingLightestGray
    url("/images/course-landing/target-section-bg.png") 50% 0 no-repeat;

    &__faq-item {
        background: $white;
        border-radius: 15px;
        margin-bottom: 6px;

        @media only screen and (min-width: 767px) {
            max-width: 520px;
            margin: 0 auto 6px auto;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 620px;
        }

        &.isActive {
            .course-faq-btm__faq-content {
                max-height: 1200px;
                padding: 15px;
            }

            .course-faq-btm__faq-title {
                strong {
                    color: #DB38A4;
                }

                &:after {
                    background:
                            url("/images/course-landing/minus-icon-blue.svg")
                            $landingGray no-repeat 50% 50%;
                }
            }
        }
    }

    &__faq-title {
        padding: 15px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        position: relative;
        padding-right: 50px;

        strong {
            font-weight: 900;
            color: #7D89A1;
        }

        &:after {
            content: '';
            position: absolute;
            right: 12px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background: $landingBlue
            url("/images/course-landing/plus-icon-blue.svg") no-repeat 50% 50%;
            border-radius: 50%;
        }
    }

    &__faq-content {
        max-height: 0;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        p {
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
