<template>
    <div class="welcome-page">
        <Header isNotFixed="true"></Header>
        <BasePaymentGetCourse></BasePaymentGetCourse>
        <BFBanner></BFBanner>
        <WelcomeLandingHeader></WelcomeLandingHeader>
        <SinglePayment></SinglePayment>
        <WelcomeLandingFAQ isNoStartButton="true"></WelcomeLandingFAQ>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header/BaseHeader.vue';
import Footer from '@/components/general/Footer.vue';
import WelcomeLandingHeader from '@/components/secondary-pages/welcome-landing/WelcomeLandingHeader.vue';
import WelcomeLandingFAQ from '@/components/secondary-pages/welcome-landing/WelcomeLandingFAQ.vue';
import SinglePayment from '@/components/secondary-pages/welcome-landing/SinglePayment.vue';
import BasePaymentGetCourse from '@/components/payment/BasePaymentGetCourse.vue';
import BFBanner from '@/components/general/BFBanner.vue';
import axios from 'axios';

export default {
  name: 'BaseFinalprice',
  components: {
    Header,
    WelcomeLandingHeader,
    SinglePayment,
    BasePaymentGetCourse,
    WelcomeLandingFAQ,
    Footer,
    BFBanner,
  },
  data() {
    return {
      webinarInvite: '',
      isInviteShortCounterUsed: false,
    };
  },
  computed: {
    getUserMail() {
      return this.$store.getters.getUserMail;
    },
    meditationsInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
    isNewMeditationLanding() {
      return this.$route.name === 'New';
    },
    meditationsList() {
      return this.$store.state.meditations.meditationsList;
    },
  },
  mounted() {
    console.log(1223);
    if (this.$route.query.player) {
      this.showPreview(this.$route.query.player);
    }
  },
  methods: {
    getMainColor(groupID, medID) {
      let mainColor = '';

      if (this.getMedById(medID).mainColor) {
        return this.getMedById(medID).mainColor;
      }

      this.meditationsList.forEach((item) => {
        if (item.groupName === groupID) {
          mainColor = item.mainColor;
        }
      });

      return mainColor;
    },
    getMedById(medID) {
      let medData = [];

      this.meditationsList.forEach((group) => {
        group.list.forEach((meditation) => {
          if (String(meditation.id) === String(medID)) {
            medData = meditation;
          }
        });
      });

      return medData;
    },
    showPreview(id) {
      let groupID = '';
      let medID = '';
      let price = 0;

      this.meditationsList.forEach((item) => {
        item.list.forEach((itemList) => {
          if (String(itemList.id) === String(id)) {
            groupID = item.groupName;
            medID = itemList.url;
            price = itemList.discrPrice;
            this.$store.commit('setPlayerDescr', itemList.discrText);
          }
        });
      });

      document.documentElement.style.setProperty('--main-color', this.getMainColor(groupID, id));

      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('showPlayer');

      const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
      const currentUrl = this.$store.getters.getLocalUrl;

      if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'ViewContent', {
          content_ids: [id.toString()],
          currency: 'USD',
          content_type: 'product',
          value: price,
        }, {
          eventID: initiateEventID,
        });
      }

      axios
        .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
          amount: price,
          eventName: 'ViewContent',
          email: this.getUserMail,
          id: initiateEventID,
        });
    },
  },
  beforeDestroy() {
    this.$store.commit('setLandingUserType', '');
    document.documentElement.style.setProperty('--main-bg-color', '#ffffff');
  },
  beforeMount() {
    const userType = 'finalprice';

    this.$store.commit('setLandingUserType', userType);

    document.documentElement.style.setProperty('--main-bg-color', '#fff4f1');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

.welcome-page {
    .base-header {
        background: $white;
    }
}
</style>
