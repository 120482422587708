<template>
    <div class="consultant-list">
        <div class="consultant-list__content">
            <div class="landing-title-group">
                <div id="chooseSectionScrollMarker"
                     class="landing-title-group__hidden-marker"
                ></div>
                <div class="landing-title-group__small">КОНСУЛЬТАЦИЯ ПСИХОЛОГА</div>
                <div class="landing-title-group__mid">Выберите психолога</div>
            </div>

            <div class="consultant-list__list">
                <ConsultantLandingListItem v-for="(item, index) in info"
                                           :info="item"
                                           :key="index"
                ></ConsultantLandingListItem>
            </div>
            <div class="consultant-list__border"></div>
        </div>
    </div>
</template>

<script>
import ConsultantLandingListItem from '@/components/secondary-pages/consultant-list/ConsultantLandingListItem.vue';

export default {
  name: 'ConsultantLandingList',
  components: {
    ConsultantLandingListItem,
  },
  data() {
    return {
      info: [
        {
          glideId: 'glideElena',
          markerId: 'elenaScrollMarker',
          bgColor: '#EAE0EB',
          image: '/images/consultant-page/team-elena-lg.jpg',
          fullName: 'Елена Сягло',
          position: 'перинатальный психолог, энерготерапевт, <br>'
          + 'специалист по психосоматике.',
          mainDescr: 'В ходе сессии с Еленой вы решите любой свой '
          + 'запрос в сфере здоровья, отношений или финансов.<br><br>'
          + 'На консультации Елена станет вашим проводником на пути к'
          + ' лучшей версии себя! Вы вдохновитесь на трансформацию'
          + ' жизни, личностный, профессиональный и духовный рост,'
          + ' найдете ответы на вопросы Миссии и Смысла Жизни.',
          isFreeMeditation: true,
          oldPrice: '35 000',
          price: '25 000',
          consultationTime: '1.5 часа',
          buttonTitle: 'Хочу к Елене',
          reviewTitle: 'Результаты терапий с Еленой',
          reviewsList: [
            {
              type: 'video',
              link: 'mCYReUi2HiU',
            },
            {
              type: 'video',
              link: 'foFYXpJ5EnA',
            },
            {
              type: 'video',
              link: 'jQC8R0xB3sc',
            },
            {
              type: 'video',
              link: 'Ja814TDGM1Y',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/elena-1.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/elena-2.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/elena-3.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/elena-4.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/elena-5.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/elena-6.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/elena-7.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/elena-8.JPG',
            },
          ],
        },
        {
          glideId: 'glideInna',
          markerId: 'innaScrollMarker',
          bgColor: '#FBE7D8',
          image: '/images/consultant-page/team-inna-lg.jpg',
          fullName: 'Инна Дисковец',
          position: 'перинатальный психолог',
          mainDescr: 'Инна станет вашим проводником на'
          + ' пути к материнству, обретению гармонии и спокойствия внутри.<br><br>'
          + 'В своей работе она применяет инструменты телесно ориентированного '
          + 'подхода, гипнотерапии, образной терапии, '
          + 'методы Psy2.0, работу с метафорическими ассоциативными картами.',
          isFreeMeditation: false,
          oldPrice: '5 000',
          price: '5 000',
          buttonTitle: 'Хочу к Инне',
          reviewTitle: 'Результаты терапий с Инной',
          consultationTime: '1.5 часа',
          reviewsList: [
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-1.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-2.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-3.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-4.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-5.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-6.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-7.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-8.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-9.JPG',
            },
            {
              type: 'image',
              link: '/images/consultant-page/reviews/inna-10.JPG',
            },
          ],
        },
      ],
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/landing";

.consultant-list {
    padding: 1px 24px 40px 24px;
    background: $white;

    .landing-title-group {
        margin-top: 1px;
    }

    .landing-title-group__mid {
        line-height: 26px;
        color: $main-blue;
    }

    &__content {
        position: relative;
        z-index: 2;
    }

    &__list {

    }
}
</style>
