<template>
    <div class="mh-club-header">
        <div class="mh-club-header__top-img">
            <img src="/images/marathon/club-mh-header.jpg">
        </div>
        <div class="mh-club-content">
            <div class="mh-club-header__title" v-if="clubFull">
                Регистрация в клубе<br>
                «В ожидании чуда»
            </div>
            <div class="mh-club-header__title" v-else>
                РЕГИСТРАЦИЯ В КЛУБЕ<br>
                МЕДИТАЦИЙ И ТРАНСФОРМАЦИЙ
            </div>

            <template v-if="clubFull">
                <div class="mh-club-header__subtitle">Начало сразу после оплаты</div>

                <button class="mh-club-header__button" @click="scrollTOForm()">
                    хочу в клуб
                </button>

                <div class="mh-club-header__title-h2">
                    Что вас ждет в клубе?
                </div>

                <div class="mh-club-header__expectations">
                    <ul>
                        <li>
                            практические занятия с
                            Еленой <b>в живом формате</b>
                            2 раза в неделю
                        </li>
                        <li>
                            <b>приглашенные эксперты в теме</b>
                            бесплодия, беременности и материнства
                        </li>
                        <li>
                            <b>закрытый чат в телеграм,</b> где
                            24/7 вы сможете получить поддержку
                        </li>
                        <li>дополнительные <b>подарки и бонусы</b></li>
                    </ul>
                </div>
            </template>

            <div class="mh-club-header__form" id="form-block">
                <div class="mh-club-header__extra-header" v-if="!clubFull">
                  специальное<br>
                  предложение для<br>
                  участниц марафона
                </div>
                <div class="mh-club-header__form-title">выбрать тариф</div>
                <template v-if="clubFull">
                    <iframe src="https://course.elena.shop/pl/lite/widget/widget?&amp;id=958687&amp;ref=&amp;loc=file%3A%2F%2F%2FC%3A%2FOpenServer%2Fdomains%2Felena.shop.local%2Fv2%2Fsrc%2Fcomponents%2Fdialog%2Ftemplates%2Fsdfds.html" allowfullscreen="allowfullscreen" class="600" id="e81c18a7b2fa43c13dcacf6793a080dbfcfe8821" name="600" style="width: 100%; height: 780px; border: none; overflow: hidden;"></iframe>
                </template>
                <template v-else>
                    <iframe src="https://course.elena.shop/pl/lite/widget/widget?&amp;id=958693&amp;ref=&amp;loc=file%3A%2F%2F%2FC%3A%2FOpenServer%2Fdomains%2Felena.shop.local%2Fv2%2Fsrc%2Fcomponents%2Fdialog%2Ftemplates%2Fsdfds.html" allowfullscreen="allowfullscreen" class="600" id="e81c18a7b2fa43c13dcacf6793a080dbfcfe8821" name="600" style="width: 100%; height: 780px; border: none; overflow: hidden;"></iframe>
                </template>
            </div>
        </div>
        <div class="mh-club-header__bottom-img">
            <img src="/images/marathon/club-mh-footer.jpg">
        </div>
    </div>
</template>

<script>
export default {
  name: 'MH-club-Header',
  data() {
    return {
      clubFull: this.$route.name === 'ClubMarathonFull',
    };
  },
  computed: {

  },
  methods: {
    smoothScrollTo(target) {
      const element = document.querySelector(target);
      if (element) {
        const offsetTop = element.getBoundingClientRect().top;
        const scrollY = window.scrollY || window.pageYOffset;

        // Вычисляем позицию, к которой нужно проскроллить
        const targetY = scrollY + offsetTop;

        // Используем requestAnimationFrame для плавности анимации
        const scrollAnimation = () => {
          const scrollStep = Math.max(1, Math.abs(targetY - window.scrollY) / 10);

          if (window.scrollY < targetY) {
            window.scrollTo(0, Math.min(window.scrollY + scrollStep, targetY));
            if (window.scrollY < targetY) requestAnimationFrame(scrollAnimation);
          } else {
            window.scrollTo(0, Math.max(window.scrollY - scrollStep, targetY));
            if (window.scrollY > targetY) requestAnimationFrame(scrollAnimation);
          }
        };

        requestAnimationFrame(scrollAnimation);
      }
    },
    scrollTOForm() {
      this.smoothScrollTo('#form-block');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";
 @import "../../../assets/scss/common/mh-landing";

 .mh-club-content {
     padding: 0 20px;
     max-width: 650px;
     margin: auto;
     position: relative;
     z-index: 3;
 }

.mh-club-header {
    position: relative;
    color: $white;
    font-family: Montserrat;
    background: #754346;

    &__extra-header {
      border-radius: 20px 20px 0 0;
      background: linear-gradient(105deg, #CD4E60 0%, #B61450 100%);
      box-shadow: 0 20px 40px 0 rgba(47, 5, 18, 0.20);
      color: #FFF;
      text-align: center;
      text-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.30);
      font-family: Montserrat;
      font-size: 19px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 130%;
      padding: 20px;
      margin: -15px 0 30px 0;
    }

    &__form-title {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 26px */
        text-transform: uppercase;
        margin: 15px 0 0 0;
    }

    &__top-img {
         margin-bottom: -30px;
         position: relative;

         &:before {
             position: absolute;
             left: 0;
             bottom: 0;
             content: '';
             height: 60px;
             width: 100%;
             background: linear-gradient(transparent, #754346);
         }

         img {
             max-width: 100%;
             width: 100%;
             max-height: 300px;
             object-fit: cover;
         }
     }

    &__bottom-img {
        margin-top: -30px;
        position: relative;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            height: 60px;
            width: 100%;
            background: linear-gradient(#754346, transparent);
        }

        img {
            max-width: 100%;
            width: 100%;
            max-height: 300px;
            object-fit: cover;
            display: block;
        }
    }

    &:before {
        background: url("/images/marathon/club-mh-header.jpg") no-repeat ;
        background-size: contain;
    }

    &__expectations {
        color: #FFF;
        font-size: 17px;
        font-weight: 400;
        line-height: 130%; /* 22.1px */

        ul {
            list-style: disc;
            padding-left: 45px;

            li {
                margin: 25px 0;
                display: list-item;

                b {
                    font-weight: 600;
                    color: #FAE100;
                }
            }
        }
    }

    &__button {
        color: $white;
        text-align: center;
        text-shadow: 0 0.7281553149223328px 1.4563106298446655px 0 rgba(0, 0, 0, 0.20);
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 35px */
        text-transform: uppercase;
        display: block;
        width: 280px;
        height: 75px;
        flex-shrink: 0;
        filter: drop-shadow(0px 7.281552791595459px 21.844661712646484px rgba(41, 41, 53, 0.40));
        margin: 45px auto 50px auto;
        border-radius: 15px;
        background: url("/images/marathon/mh-club-button.png");
        background-size: contain;
        border: none;
    }

    &__title {
        text-align: center;
        text-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.40);
        font-size: 22px;
        font-weight: 600;
        line-height: 130%; /* 28.6px */
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    &__subtitle {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%; /* 24px */
    }

    &__title-h2 {
        text-align: center;
        text-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.40);
        font-size: 20px;
        font-weight: 600;
        line-height: 130%; /* 26px */
        text-transform: uppercase;
        margin: 30px 0;
    }

    &__form {
        border-radius: 20px;
        border: 1px solid #FF9DC6;
        background: #FFF;
        box-shadow: 0 20px 40px 0 rgba(47, 5, 18, 0.20);
        margin: 50px 0 0 0;
        padding: 15px 0;
    }
}
</style>
