<template>
    <div class="tg-promo">
        <div class="tg-promo__image">
            <img src="/images/consultant-page/elena-gradient.png">
        </div>

        <div class="tg-promo__title-block">
            Хотите стать участником<br>
            публичной терапии в<br>
            Инстаграме?
        </div>

        <div class="tg-promo__descr">
            Подпишитесь на чатбота в телеграм,
            чтобы не пропустить прямую трансляции с Еленой.
        </div>

        <div class="tg-promo__accent-title">
            БЕСПЛАТНО
        </div>
        <a href="tg://resolve?domain=elena_psybot&start=ml4"
           class="tg-promo__subscribe"
           target="_blank"
        >
            <img src="/images/consultant-page/tg-icon.svg">
            Подписаться
        </a>

    </div>
</template>

<script>
export default {
  name: 'ConsultantLandingTgPromo',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/forms";

.tg-promo {
    margin: 60px 24px 20px 24px;
    padding: 40px 24px 40px 24px;
    background: $main-blue;
    color: $white;
    text-align: center;
    border-radius: 30px;

    &__image {
        height: 110px;
        margin-top: -100px;
        margin-bottom: 40px;
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;

        img {
            width: 110px;
            height: 110px;
            flex-shrink: 0;
        }
    }

    &__title-block {
        position: relative;
        margin: 25px 0 20px 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;

        &:before {
            width: 135px;
            height: 72px;
            border: 1px solid rgba(255, 255, 255, 0.66);
            content: '';
            border-radius: 50%;
            transform: rotate(33.89deg);
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -68px;
        }
    }

    &__descr {
        font-size: 12px;
        line-height: 20px;
        margin: 15px 0;
    }

    &__accent-title {
        font-weight: 600;
        font-size: 34px;
        line-height: 140%;
        margin: 30px 0;
    }

    &__subscribe {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: $white;

        img {
            margin-right: 12px;
        }
    }
}
</style>
