<template>
    <div class="course-review">
        <div class="course-review__inc">
            <div class="course-review__hr">
                <div class="course-review__hr-line"></div>
                <div class="course-review__hr-dot"></div>
                <div class="course-review__hr-line"></div>
            </div>
            <div class="course-landing-title">
                <div class="hiddenLabel" id="reviewsSection"></div>
                Видео-отзывы после двух потоков курса
            </div>

            <div class="course-review__list">
                <div class="course-review__list-item">
                    <div class="course-review__item-title">Оксана Лобас, 35 лет</div>
                    <div class="course-review__item-insta">
                        <a href="https://www.instagram.com/oksana__lobas/"
                           target="_blank"
                        >
                            @oksana__lobas
                        </a>
                    </div>
                    <div class="course-review__item-content">
                        <div class="course-review__video">
                            <iframe
                                    width="100%"
                                    height="400"
                                    src="https://www.youtube.com/embed/mu6kOWcMIQY?modestbranding=1&rel=0"
                                    frameborder="0"
                                    allowfullscreen
                                    modestbranding='0'
                                    v-if="bgVideosVisability"
                            ></iframe>
                        </div>
                    </div>
                </div>

                <div class="course-review__list-item">
                    <div class="course-review__item-title">Ольга Андронаке</div>
                    <div class="course-review__item-insta">
                        <a href="https://www.instagram.com/_olgaandronache/"
                           target="_blank"
                        >
                            @_olgaandronache
                        </a>
                    </div>
                    <div class="course-review__item-content">
                        <div class="course-review__bottom">
                            <div class="course-review__video">
                                <iframe
                                        width="100%"
                                        height="400"
                                        src="https://www.youtube.com/embed/csoFoW5KgxI?modestbranding=1&rel=0"
                                        frameborder="0"
                                        allowfullscreen
                                        modestbranding='0'
                                        v-if="bgVideosVisability"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <button @click="showAllReviews()"
                    class="es-button black es-button--bordered"
            >
                Смотреть больше видео
            </button>

            <vue-bottom-sheet ref="myBottomSheet" :swipe-able="false"
                              @opened="bottomSheetOpened()"
                              @closed="bottomSheetClosed()"
            >
                <div class="course-review__other-videos">
                    <div class="course-review__other-title">Все видео-отзывы:</div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/zR_ISHp_zYM?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/HKXuHycAJwU?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/d2Oq6R3uwyM?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/oZjCjT9WRow?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/QoMlctZRw_I?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/XbvtiPH3gW8?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/QFTruvtphLE?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/SFA3gWZKCzE?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/sxKkhNhU2LE?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/pmcF-oogHyY?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/dSLYpySNQKY?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/416hW0rUucY?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/qomhvAcrjDo?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/JSsW23fbULk?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/x83JwNH-VvM?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/hAtvMmBUGG8?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/4TAevE3uqKQ?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/TRaCK35apM4?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/O35ZrxTqfZ8?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/g0MFwd17sNc?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/1ct1mzcYLAQ?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/5zdrf7Azm2M?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/cy4EUEOB27U?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/iOk3N-ZTS3I?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/Jo_VFl1cZKw?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/V5tRX-Gdkqs?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/8TTLiSiKv94?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/S7cN8QbcHRY?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/h9xdSz2nIEA?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/QFqVL1TPAsI?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/5v6xd3KQiGU?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/GswQHs2Z7TU?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>

                    <div class="course-review__other-video-item">
                        <iframe
                                width="100%"
                                height="215"
                                src="https://www.youtube.com/embed/lcnEqgFiJys?modestbranding=1&rel=0"
                                frameborder="0"
                                allowfullscreen
                                modestbranding='0'
                        ></iframe>
                    </div>
                </div>
            </vue-bottom-sheet>

            <vue-easy-lightbox
                    escDisabled
                    moveDisabled
                    :visible="visible"
                    :imgs="imgs"
                    :index="index"
                    @hide="handleHide"
            ></vue-easy-lightbox>
        </div>
    </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';

export default {
  name: 'CourseReviews',
  components: {
    VueEasyLightbox,
    VueBottomSheet,
  },
  data() {
    return {
      reviewsCount: 12,
      imgs: '',
      visible: false,
      bgVideosVisability: true,
    };
  },
  computed: {
    textMoteButton() {
      return this.reviewsCount === 12 ? 'Показать еще' : 'Скрыть';
    },
  },
  methods: {
    getLink(index) {
      return `/images/course-landing/reviews/${index}.jpg`;
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    showSingle(index) {
      this.imgs = `/images/course-landing/reviews/${index}.jpg`;
      this.show();
    },
    tootleMore() {
      if (this.reviewsCount !== 12) {
        this.reviewsCount = 12;
      } else {
        this.reviewsCount = 23;
      }
    },
    showAllReviews() {
      this.$refs.myBottomSheet.open();
    },
    bottomSheetOpened() {
      this.bgVideosVisability = false;
    },
    bottomSheetClosed() {
      this.bgVideosVisability = true;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-review {
    padding: 20px 23px 40px 23px;
    position: relative;
    background: $white;

    &__other-videos {
        padding: 5px 20px 20px 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__other-video-item {
        width: 47%;
        margin-bottom: 10px;
        background: rgba(0,0,0,0.05);
        border-radius: 10px;
        overflow: hidden;
        line-height: 0;
    }

    &__other-title {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 30px;
        color: #292935;
    }

    &:before {
        background: url("/images/course-landing/reviews-bg.png");
        width: 320px;
        height: 354px;
        content: '';
        position: absolute;
        z-index: 1;
        left: calc(50% - 150px);
        top: -70px;
    }

    &__inc {
        position: relative;
        z-index: 2;
    }

    &__descr {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        text-align: center;

        @media only screen and (min-width: 767px) {
            font-size: 14px;
            line-height: 150%;
            max-width: 530px;
            margin: 0 auto;
        }

        @media only screen and (min-width: 1024px) {
           max-width: 720px;
        }
    }

    &__descr-large {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        margin: 25px 0;

        @media only screen and (min-width: 767px) {
            font-size: 18px;
            max-width: 530px;
            margin: 30px auto;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 720px;
            font-size: 21px;
            margin: 48px auto;
        }

        strong {
            font-weight: 600;
            color: #7D89A1;
        }
    }

    &__hr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -22px 0 30px 0;

        @media only screen and (min-width: 767px) {
            margin: 30px 0 60px 0;
        }

        &-line {
            width: calc(50% - 23px);
            background: #D1D6E7;
            height: 1px;
        }

        &-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #D1D6E7;
        }
    }

    &__list {
        margin: 40px 0;

        @media only screen and (min-width: 767px) {
            display: flex;
            justify-content: space-around;
        }

        @media only screen and (min-width: 1024px) {
            max-width: 620px;
            margin: 60px auto 40px auto;
        }
    }

    &__list-item {
        background: #F3F6FC;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 30px;
        padding: 23px 23px 23px 50px;
        position: relative;
        color: #292935;
        margin-bottom: 30px;

        @media only screen and (min-width: 767px) {
            width: 280px;
            flex-shrink: 0;
            box-sizing: border-box;
            margin-bottom: 0;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            margin: 20px 0;
        }

        strong {
            font-weight: 500;
        }

        &:before {
            background: url("/images/course-landing/quote-icon-blue.svg");
            width: 18px;
            height: 17px;
            content: '';
            position: absolute;
            left: 18px;
            top: 28px;
        }
    }

    &__item-content {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 60px);
        box-sizing: border-box;

        .large {
            font-size: 14px;
        }

        ul {
            margin-top: 5px;

            li {
                margin-bottom: 2px;
                list-style: none;
                padding-left: 10px;
                position: relative;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 9px;
                    content: '';
                    width: 2px;
                    height: 2px;
                    background: $landingBlue;
                    border-radius: 50%;
                }
            }
        }
    }

    &__item-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
    }

    &__item-insta {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 25px;

        a {
            color: $main-blue;
        }
    }

    &__video {
        margin-left: -27px;
        transform: translateZ(0);
        position: relative;
    }

    &__image-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        align-items: center;
        justify-content: center;
        margin: 40px 0 20px 0;

        @media only screen and (min-width: 1024px) {
            max-width: 720px;
            margin: 50px auto 20px auto;
        }
    }

    &__image-item {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        width: 87px;
        height: 87px;
        margin: 5px auto;

        @media only screen and (min-width: 767px) {
            width: 192px;
            height: 192px;
        }

        @media only screen and (min-width: 1024px) {
            width: 229px;
            height: 229px;
        }

        img {
            display: block;
            max-width: 100%;
        }

        &:before {
            position: absolute;
            background: rgba(0,0,0,0.4)
            url("/images/course-landing/zoom-icon-white.svg") no-repeat 50% 50%;
            left: 0;
            top: 0;
            content: '';
            z-index: 5;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    .es-button {
        @media only screen and (min-width: 767px) {
            width: 250px;
            margin: 10px auto;
        }
    }
}
</style>
