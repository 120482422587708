<template>
  <div  class="module-card__item"
        :class="{'isPayed': meditation.isPayed, 'isNotPayedHidden': hideNotPayed}"
  >
    <div class="module-card__item-title link"
         @click.stop="showPreview(meditation.id)"
         v-if="isCabinet && meditation.isPayed"
    >
      {{meditation.name}}
    </div>

    <div class="module-card__item-title" v-else>
      {{meditation.name}}
    </div>
    <div class="module-card__item-time">
      <div class="module-card__item-time" v-if="isCabinet">
        <div>{{showType(meditation.type)}}</div>
        <div>Длительность: {{meditation.discrTime}}</div>
      </div>

      <div class="module-card__item-time" v-else>
        <template v-if="meditation.isPayed">
          Медитация куплена
        </template>
        <template v-else>
          Длительность: {{meditation.discrTime}}
        </template>
      </div>
    </div>
    <div class="module-card__item-reviews">
      <router-link to="/my"
                   v-if="meditation.isPayed && !isCabinet"
      >
        Посмотреть в ЛК
      </router-link>
      <span
        @click="showReviewsDialog(
                                        meditation.groupId,
                                        meditation.url,
                                        meditation.id,
                                        meditation.discrPrice
                                    )"
        v-if="!meditation.isPayed && meditation.reviews.length"
      >
                                    Отзывы ({{meditation.reviews.length}})
                                </span>
    </div>
    <div class="module-card__price-cover"
         v-if="!meditation.isPayed"
    >
      <div class="module-card__price" v-if="isGetCourse">
        <div class="module-card__price-future">
          {{priceWithSale(meditation.getCoursePrice, 0)}} ₽
        </div>
        <div
             class="module-card__price-base"
             v-if="meditation.getCoursePrice != priceWithSale(meditation.getCoursePrice, 0)"
        >
          {{Math.round(meditation.getCoursePrice)}} ₽
        </div>
      </div>
      <div class="module-card__price" v-else>
        <div class="module-card__price-future">
          {{priceWithSale(meditation.discrPrice, 1)}} $
        </div>
        <div class="module-card__price-base">
          {{Math.round(meditation.discrPrice)}} $
        </div>
      </div>
      <div class="module-card__actions">
        <button
          class="es-button es-button--rounded play es-button--bordered white"
          @click.stop="showPreview(meditation.id)"
        ></button>
        <button
          @click.stop="addToCart(
                                                meditation.id,
                                                meditation.discrPrice
                                            )"
          class="es-button es-button--rounded cart"
          :disabled="isInCart(meditation.id)"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CartItem',
  props: {
    meditation: Object,
    hideNotPayed: Boolean,
  },
  data() {
    return {
      complete: false,
      isViewContentSended: [],
    };
  },
  computed: {
    isGetCourse() {
      return this.$store.state.general.isGetCourse;
    },
    isCabinet() {
      return this.$route.name === 'Cabinet' || this.$route.name === 'CabinetFull';
    },
    cartList() {
      return this.$store.state.meditations.cartList;
    },
    meditationsList() {
      return this.$store.state.meditations.meditationsList;
    },
    saleCoof() {
      return this.$store.state.general.saleCoof;
    },
  },
  methods: {
    checkIsViewContentSended(id) {
      let isInArray = false;

      this.isViewContentSended.forEach((itemId) => {
        if (id === itemId) {
          isInArray = true;
        }
      });

      return isInArray;
    },
    getMedById(medID) {
      let medData = [];

      this.meditationsList.forEach((group) => {
        group.list.forEach((meditation) => {
          if (String(meditation.id) === String(medID)) {
            medData = meditation;
          }
        });
      });

      return medData;
    },
    getMainColor(groupID, medID) {
      let mainColor = '';

      console.log(this.getMedById(medID));

      if (this.getMedById(medID).mainColor) {
        return this.getMedById(medID).mainColor;
      }

      this.meditationsList.forEach((item) => {
        if (item.groupName === groupID) {
          mainColor = item.mainColor;
        }
      });

      return mainColor;
    },
    priceWithSale(price, rounding) {
      return (price * this.saleCoof).toFixed(rounding);
    },
    showReviewsDialog(groupID, medID, id, price) {
      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('setCurrentTemplate', 'review');
      this.$store.commit('showDialog');

      if (!this.checkIsViewContentSended(id)) {
        this.isViewContentSended.push(id);
        const currentUrl = this.$store.getters.getLocalUrl;
        const initiateEventID = Math.floor(Math.random() * (1e12 + 1));

        if (typeof window.fbq !== 'undefined') {
          window.fbq('track', 'ViewContent', {
            content_ids: [id.toString()],
            currency: 'USD',
            content_type: 'product',
            value: price,
          }, {
            eventID: initiateEventID,
          });
        }

        axios
          .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
            amount: price,
            eventName: 'ViewContent',
            email: this.getUserMail,
            id: initiateEventID,
          });
      }
    },
    isInCart(id) {
      return this.cartList.includes(id);
    },
    showType(type) {
      if (type === 'mediatation') {
        return 'Медитация';
      }

      return 'Вебинар';
    },
    addToCart(id, price) {
      if (this.isGetCourse) {
        console.log(111);
        this.$store.commit('hideDialog');
        this.$store.commit('showPaymentGetCourse');
        this.$store.commit('addToCart', id);

        return;
      }

      this.$store.commit('setCurrentTemplate', 'cart-sale');
      this.$store.commit('showDialog');

      const initiateEventID = Math.floor(Math.random() * (1e12 + 1));
      const currentUrl = this.$store.getters.getLocalUrl;

      if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'AddToCart', {
          content_ids: [id.toString()],
          content_type: 'product',
          currency: 'USD',
          value: price,
        }, {
          eventID: initiateEventID,
        });
      }

      axios
        .post(`${currentUrl}/v2/api/sendFBServerEvent.php`, {
          amount: price,
          eventName: 'AddToCart',
          email: this.getUserMail,
          id: initiateEventID,
        });
    },
    getUrl(groupName, url) {
      return `/meditation-group/${groupName}/${url}`;
    },
    showPreview(id) {
      let groupID = '';
      let medID = '';
      let type = '';

      this.meditationsList.forEach((item) => {
        item.list.forEach((itemList) => {
          if (itemList.id === id) {
            groupID = item.groupName;
            medID = itemList.url;
            type = itemList.type;
            this.$store.commit('setPlayerDescr', itemList.discrText);
          }
        });
      });

      document.documentElement.style.setProperty('--main-color', this.getMainColor(groupID, id));

      this.$store.commit('setCurrentPage', {
        group: groupID,
        name: medID,
      });

      this.$store.commit('showPlayer', type);
    },
    startPayment(priceToPay, priceOld, type, moduleName) {
      if (this.priceBaseSale === 0) {
        this.isErrorVisible = true;
        return false;
      }

      this.$store.commit('markSingleMeditationsForPay', moduleName);
      this.$store.commit('setSinglePagePayment', true);
      this.$store.commit('setLandingPaymentPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentType', type);
      this.$store.commit('setPriceToPay', priceToPay);
      this.$store.commit('setLandingPaymentFullPrice', priceOld);
      this.$store.commit('showPayment');

      return true;
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

 .module-card {
    &__error {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        margin: 25px 0;
        color: $main-red;
    }

    &__content {
        background: $white;
        border-radius: 10px 10px 0 0;
        position: relative;
        z-index: 8;
    }

    &__avialible {
        position: absolute;
        height: 31px;
        line-height: 31px;
        color: $white;
        left: 0;
        top: -15px;
        padding: 0 24px;
        border-radius: 0 16px 16px 0;
        font-size: 14px;
        font-weight: 500;
        background: #1AC655;
    }

    &__item {
        box-sizing: border-box;
        overflow: hidden;
        margin: 10px 0 20px 0;
        width: 100%;
        position: relative;

        a:visited {
            color: $main-blue;
        }

        &.isPayed {
            background: #F3F5F9;
        }

        &:not(.isPayed).isNotPayedHidden {
            display: none;
        }

        @media only screen and (max-width: 767px) {
            width: auto;
            margin: 10px 0 10px 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__title {
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 5px;
        padding-left: 5px;

        @media only screen and (max-width: 767px) {
            font-size: 18px;
        }
    }

    &__item {
        background: #EEF2FF;
        border-radius: 10px;
        padding: 13px 22px;
        margin: 7px 0;
    }

    &__price-cover {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    &__price-future {
        color: $main-red;
        font-size: 19px;
    }

    &__price-base {
        text-decoration: line-through;
        margin-left: 10px;
    }

    &__price {
        font-size: 16px;
        font-weight: 600;
        color: $main-blue;
        display: flex;
        align-items: center;
    }

    &__actions {
        display: flex;

        & > button {
            margin-left: 10px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    &__item-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        line-height: 22px;
        color: $black!important;

      &.link {
        text-decoration: underline;
      }
    }

    &__item-time {
        font-size: 12px;
        line-height: 18px;
        color: #908C8C;
        margin-bottom: 5px;
    }

    &__item-reviews {
        font-size: 12px;
        line-height: 18px;
        color: $main-blue;
        text-decoration: underline;
    }

    &__list {
        overflow: hidden;
        transition: all 200ms;
        max-height: 1000px;
        padding: 15px 0 0 0;

        @media only screen and (max-width: 767px) {
            margin-bottom: 1px;
        }

        &.hidden {
            max-height: 0;
            padding: 0 0 0 10px;
        }

        .underlined {
            .module-card__list-text {
                text-decoration: underline;
            }
        }

        .es-checkbox-label {
            width: 20px;
            height: 20px;
            border: solid $main-blue 1px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 1px;

            @media only screen and (max-width: 767px) {
                top: 4px;
            }

            &.isChecked {
                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    background: url("/images/landing/check-icon-blue.svg") no-repeat 50% 50%;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
        }

        &.isCheckList {
            li {
                cursor: pointer;

                &:before {
                    display: none;
                }
            }
        }

        li {
            list-style: none;
            padding-left: 35px;
            position: relative;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 20px;

            a {
                color: $main-blue;
            }

            @media only screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .light {
                font-weight: 400;
                line-height: 30px;

                @media only screen and (max-width: 767px) {
                    line-height: 26px;
                }
            }

            &:before {
                position: absolute;
                width: 20px;
                height: 20px;
                background: url("/images/check-icon-green.svg");
                content: '';
                left: 0;
                top: 4px;
            }
        }
    }
}
</style>
