<template>
    <div class="course-page">
        <CourseHeader20></CourseHeader20>
        <CourseDescription20 v-if="!isShortPage"></CourseDescription20>
        <CourseTargetGroup v-if="!isShortPage"></CourseTargetGroup>
        <CoursePollResult20 v-if="!isShortPage"></CoursePollResult20>
        <CourseChooseFormat></CourseChooseFormat>
        <CoursePaymentFAQ20 v-if="!isShortPage"></CoursePaymentFAQ20>
        <CourseQuestionsForm v-if="!isShortPage"></CourseQuestionsForm>
        <CourseProgram20 v-if="!isShortPage"></CourseProgram20>
        <CourseAboutAuthor20 v-if="!isShortPage"></CourseAboutAuthor20>
        <CourseReviews20 v-if="!isShortPage"></CourseReviews20>
        <CourseCases20 v-if="!isShortPage"></CourseCases20>
        <CourseFAQ20 v-if="!isShortPage"></CourseFAQ20>
        <Footer v-if="!isShortPage"></Footer>
    </div>
</template>

<script>
import CourseHeader20 from '@/components/secondary-pages/course-landing/CourseHeader20.vue';
import CourseDescription20 from '@/components/secondary-pages/course-landing/CourseDescription20.vue';
import CourseTargetGroup from '@/components/secondary-pages/course-landing/CourseTargetGroup.vue';
import CoursePollResult20 from '@/components/secondary-pages/course-landing/CoursePollResult20.vue';
import CourseChooseFormat from '@/components/secondary-pages/course-landing/CourseChooseFormat.vue';
import CoursePaymentFAQ20 from '@/components/secondary-pages/course-landing/CoursePaymentFAQ20.vue';
import CourseQuestionsForm from '@/components/secondary-pages/course-landing/CourseQuestionsForm.vue';
import CourseProgram20 from '@/components/secondary-pages/course-landing/CourseProgram20.vue';
import CourseAboutAuthor20 from '@/components/secondary-pages/course-landing/CourseAboutAuthor20.vue';
import CourseReviews20 from '@/components/secondary-pages/course-landing/CourseReviews20.vue';
import CourseCases20 from '@/components/secondary-pages/course-landing/CourseCases20.vue';
import CourseFAQ20 from '@/components/secondary-pages/course-landing/CourseFAQ20.vue';
import Footer from '@/components/secondary-pages/course-landing/Footer.vue';

export default {
  name: 'BaseConsultatantList',
  components: {
    CourseHeader20,
    CourseDescription20,
    CourseTargetGroup,
    CoursePollResult20,
    CourseChooseFormat,
    CoursePaymentFAQ20,
    CourseQuestionsForm,
    CourseProgram20,
    CourseAboutAuthor20,
    CourseReviews20,
    CourseCases20,
    CourseFAQ20,
    Footer,
  },
  data() {
    return {
    };
  },
  computed: {
    isPaymentPage() {
      return this.$route.name === 'ConsultatantPayment';
    },
    isShortPage() {
      return this.$route.name === 'Courseland20Short';
    },
  },
  beforeMount() {
    document.documentElement.style.setProperty('--main-bg-color', '#f2f5fc');
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/scss/common/variables";

body {
    background: red;
}

.course-page {
}
</style>
