<template>
    <div class="course-header" id="hiddenTop">
        <div class="course-header__title-small">Авторский курс Елены Сягло</div>
        <div class="course-header__title">
            Ключ к <br> материнству <span class="course-header__title-count"></span>
        </div>

        <div class="course-header__info">
            <div class="course-header__info-item">
                <div class="course-header__info-header">Формат</div>
                Онлайн
            </div>
            <div class="course-header__info-item">
                <div class="course-header__info-header">Длительность</div>
                10 недель
            </div>
            <div class="course-header__info-item isActive">
                <div class="course-header__info-header">Старт</div>
                2 октября
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CourseHeader',
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/landing";
 @import "../../../assets/scss/common/forms";

.course-header {
    text-align: center;
    min-height: 400px;
    background: url("/images/course-landing/course-header-bg.jpg") 50% 95px no-repeat #f3f6fd;
    background-size: 336px;
    padding: 40px 23px 30px 23px;

    @media only screen and (min-width: 1024px) {
        padding-bottom: 120px;
    }

    @media only screen and (min-width: 767px) {
        background:
                url("/images/course-landing/course-header-bg-lg.jpg") 50% 103px no-repeat #f3f6fd;
        background-size: 776px;
    }

    @media only screen and (min-width: 1024px) {
        background:
                url("/images/course-landing/course-header-bg-lg.jpg") 50% 60px no-repeat #f3f6fd;
        background-size: 1024px;
    }

    &__title-small {
        font-size: 16px;
        line-height: 24px;
        color: #7D89A1;
        margin-bottom: 10px;
        text-align: left;

        @media only screen and (min-width: 767px) {
            font-weight: 400;
            font-size: 26px;
            line-height: 150%;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    &__title-count {
        &:before {
            background: #292935;
            width: 66px;
            height: 64px;
            border-radius: 12px;
            transform: rotate(-15deg);
            content: '';
            position: absolute;
            z-index: 0;
            top: -16px;
            left: -12px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
        }

        &:after {
            content: '3.0';
            position: relative;
            z-index: 2;
            width: 66px;
        }

        color: $white;
        font-weight: 700;
        font-size: 28px;
        line-height: 110%;
        text-align: center;
        width: 66px;
        position: relative;
        margin-left: 23px;
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 35px;
        text-align: left;

        @media only screen and (min-width: 767px) {
            font-size: 52px;
            line-height: 110%;
            text-align: center;

            br {
                display: none;
            }
        }
    }

    &__info {
        display: flex;
        background: #FBFBFE;
        box-shadow: 0 4px 13px rgba(125, 137, 161, 0.19);
        border-radius: 15px;
        color: $landingBlue;
        font-weight: 600;
        font-size: 14px;
        line-height: 48px;
        margin: 253px auto 0 auto;
        max-width: 512px;

        @media only screen and (min-width: 767px) {
            margin-top: 500px;
        }

        @media only screen and (min-width: 1024px) {
            margin-top: 617px;
        }
    }

    &__info-item {
        border-radius: 15px;
        position: relative;
        height: 48px;
        width: 100%;

        &.isActive {
            background: $landingBlue;
            color: $white;
        }
    }

    &__info-header {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        width: 100%;
        position: absolute;
        left: 0;
        top: -25px;
        color: #7D89A1;
    }
}
</style>
